module.exports = {
  "heroBanner.title": "Welcome to DevLand",
  "heroBanner.message":
    "DevLand is a community made by and for software developers. Join us and start building the future of the web!",
  "heroBanner.visitDiscordButton": "Visit us at discord",
  "sessionBox.createAccountButton": "Register",
  "sessionBox.importAccountButton": "Import account",
  "alertAccountCreationModal.title": "Before creating your account",
  "alertAccountCreationModal.text1":
    "DevLand Network uses an anonymous authentication model based in Stellar addresses.",
  "alertAccountCreationModal.text2":
    "A pair of public and private keys will be generated for you. The public key serves as a user, while the private key works as a passwords.",
  "alertAccountCreationModal.text3":
    "This keypair can be also used with any Stellar wallet, by importing the private key.",
  "alertAccountCreationModal.text4":
    "Both keys will be provided so you could save them in a safe place. They will be also saved in the browser, but the private key will be encrypted so no other user than you could use your account. To do this we'll ask for a password that will be used to encrypt and decrypt the private key every time you use it.",
  "alertAccountCreationModal.text5":
    "Keep in mind that we have no access to your private key at any time. It is your responsability to keep it safe. If you lose it or it is stolen, we won't be able to do anything.",
  "alertAccountCreationModal.text6":
    "You can find more info in the Frequently Asked Questions page.",
  "alertAccountCreationModal.button": "I understand, I want to continue",
  "createAccountModal.title": "Create your account",
  "createAccountModal.privateKey": "Private key:",
  "createAccountModal.publicKey": "Public key:",
  "createAccountForm.passwordLabel":
    "Set a password to encrypt your private key",
  "createAccountForm.repeatPasswordLabel": "Repeat your password",
  "createAccountForm.emptyPasswordError": "Error: Password is empty.",
  "createAccountForm.shortPasswordError":
    "Error: Password is shorter than 8 characters.",
  "createAccountForm.differentPasswordsError": "Error: Passwords don't match.",
  "createAccountForm.submitButton": "Encrypt private key",
  "importAccountModal.title": "Import your private key",
  "importAccountForm.privateKeyLabel": "Private key of your Stellar account",
  "importAccountForm.passwordLabel": "Password to encrypt it",
  "importAccountForm.repeatPasswordLabel": "Repeat the password",
  "importAccountForm.emptyPrivateKeyError": "Error: Private key is empty.",
  "importAccountForm.emptyPasswordError": "Error: Password is empty.",
  "importAccountForm.shortPasswordError":
    "Error: Password is shorter than 8 characters.",
  "importAccountForm.differentPasswordsError": "Error: Passwords don't match.",
  "importAccountForm.submitButton": "Import account",
  "accountPage.title": "Your account",
  "accountPage.text1":
    "The authentication system works with the Stellar blockchain network.",
  "accountPage.text2":
    "Your private key works as a master password for your account. You can use it to re-import your account here or in any Stellar wallet. Keep it safe.",
  "accountPage.text3":
    "Your public key works as a user. You can share it with anyone as long as you don't mind that it gets related to your identity. Here it is:",
  "accountPage.activateAccountText":
    "Your account isn't active. To activate it you'll need to hold at least 3 XLM in it and then stablish a trustline with the DEVP token by clicking the \"Activate account\" button.",
  "accountPage.activateAccountButton": "Activate account",
  "accountPage.revealPrivateKeyButton": "Reveal private key",
  "accountPage.logoutButton": "Logout",
  "accountPage.notLoggedInWarning":
    "Create an account or import an existing one to be able to access this page",
  "showPrivateKeyModal.passwordLabel":
    "Enter your password to decrypt your private key",
  "showPrivateKeyModal.wrongPasswordError":
    "ERROR: The password you entered appears to be wrong.",
  "showPrivateKeyModal.revealPrivateKeyButton": "Reveal private key",
  "activateAccountModal.title": "Activate account",
  "activateAccountModal.operationLabel": "Operation:",
  "activateAccountModal.operation": "Enable trustline with DEVP token",
  "activateAccountModal.balance": "Current balance:",
  "activateAccountModal.maxFee": "Max fee:",
  "activateAccountModal.signButton": "Sign activation operation",
  "createUserModal.title": "Add your user info",
  "createUserForm.username": "Username",
  "createUserForm.firstName": "First name",
  "createUserForm.lastName": "Last name",
  "createUserForm.submitButton": "Create account",
  "dropdownMenu.aboutDevland": "About DevLand",
  "dropdownMenu.aboutDevp": "About DEVP",
  "dropdownMenu.aboutSnippet": "About SNIPPET",
  "dropdownMenu.roadmap": "Roadmap",
  "dropdownMenu.faq": "FAQ",
  "dropdownMenu.profile": "Profile",
  "dropdownMenu.wallet": "Wallet",
  "dropdownMenu.account": "Account",
  "dropdownMenu.logout": "Log out",
  "footer.about": "Info",
  "footer.about.DevLand": "About DevLand",
  "footer.about.Devp": "About DEVP",
  "footer.about.Snippet": "About SNIPPET",
  "footer.about.faq": "FAQ",
  "footer.roadmap": "Roadmap",
  "footer.socialMedia": "Social Media",
  "footer.tokenDisclaimer":
    "DEVP and SNIPPET tokens are not investment products nor are backed by any real asset or hold real value. They are utilities of DevLand issued as Stellar native assets to be used within the devland.network platform and related tools from the Stellar ecosystem.",
}
